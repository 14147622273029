import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  styled,
  Grid,
} from '@mui/material';
import {useLocaleNumberFormatting} from '../../../utils';

const TileImage = styled('img')`
  display: block;
  height: ${props => props.height || 150}px;
  width: auto;
  margin: auto;
`;

const TileContainer = styled(Card)`
  min-height: 450px;
`;

export const ProductTile = memo(
  ({
    isSelected,
    label,
    monthlyInstallmentAmountDifference,
    pictureUrl,
    reference,
    onButtonClick,
  }) => {
    const {t} = useTranslation();
    const {formatNumberWithCurrency} = useLocaleNumberFormatting();

    const handleButtonClick = () =>
      onButtonClick({
        isSelected,
        monthlyInstallmentAmountDifference,
        reference,
      });

    const getButtonTranslationKey = () => {
      const keySuffix = isSelected ? 'removeFromBasket' : 'addToBasket';
      return `landing.paymentPlan.button.${keySuffix}`;
    };

    return (
      <TileContainer>
        <CardContent sx={{height: '340px'}}>
          <TileImage height="200" src={pictureUrl} alt={label} />
          <Typography gutterBottom variant="subtitle1" component="div">
            {label}
          </Typography>
        </CardContent>
        <Grid item px={2} pb={1}>
          <Typography
            variant="body2"
            color="text.secondary"
            fontWeight="bold"
            component="span"
            mr={1}
          >
            {formatNumberWithCurrency(monthlyInstallmentAmountDifference)}
          </Typography>
          {t('landing.paymentPlan.title')}
        </Grid>
        <CardActions>
          <Button
            variant={isSelected ? 'text' : 'contained'}
            color="primary"
            fullWidth
            size="large"
            onClick={handleButtonClick}
          >
            {t(getButtonTranslationKey())}
          </Button>
        </CardActions>
      </TileContainer>
    );
  },
);
