import filterData from '../filterData.json';

export const getCategoriesWithFilters = translate => {
  return [
    {
      label: translate('accessories.filters.recommended.label'),
      categories: [
        {
          label: translate('accessories.filters.recommended.label'),
          code: 'recommended',
        },
      ],
    },
    {
      label: translate('accessories.filters.splitByCategories.label'),
      categories: filterData.categories,
    },
  ];
};

export const getAllCategories = translate => {
  return [
    {
      label: translate('accessories.filters.recommended.label'),
      code: 'recommended',
    },
    ...filterData.categories,
  ];
};
