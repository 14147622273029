import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  products: [],
  term: 12,
};

export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    addInitialProduct: (state, {payload}) => {
      if (state.products.length === 0) {
        state.products = [payload];
      }
    },
    addProduct: (state, {payload}) => {
      const product = {
        ...payload,
        total: payload.monthlyInstallmentAmountDifference * state.term,
      };
      state.products = [...state.products, product];
    },
    removeProduct: (state, {payload}) => {
      state.products = state.products.filter(
        item => item.reference !== payload,
      );
    },
  },
});

export const {addInitialProduct, addProduct, removeProduct} =
  basketSlice.actions;

export const getProducts = ({basket}) => basket.products;

export const getProductTotal = ({basket}) =>
  basket.products?.reduce(
    (previous, current) =>
      previous + current.monthlyInstallmentAmountDifference,
    0,
  );

export const getTerm = ({basket}) => basket.term;

export const getBasketTotal = ({basket}) =>
  basket.products?.reduce((previous, current) => previous + current.total, 0);

export default basketSlice.reducer;
