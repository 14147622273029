import React from 'react';
import {Button, Container, Grid, styled, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useLocaleNumberFormatting} from '../../../utils';
import {H1} from '../../../components';

const GreyContainer = styled('div')`
  background-color: ${props => props.theme.palette.secondary.matt};
  color: ${props => props.theme.palette.secondary.contrastText};
  position: sticky;
  top: 0px;
  z-index: 10;
`;

export const AccessoriesHeadline = ({
  costPerMonth,
  productLabel,
  onButtonClicked,
}) => {
  const {t} = useTranslation();
  const {formatNumberWithCurrency} = useLocaleNumberFormatting();

  return (
    <GreyContainer>
      <Container>
        <Grid container py={{md: 3, xs: 1}} justifyContent="center">
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={4} textAlign="center">
            <H1 nomargin>
              {`${formatNumberWithCurrency(costPerMonth)} ${t(
                'landing.paymentPlan.title',
              )}`}
            </H1>
            <Typography variant="caption">{productLabel}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            pt={{md: 4, xs: 2}}
            pb={{xs: 2}}
            textAlign="center"
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={onButtonClicked}
            >
              {t(`accessories.button.goToBasket`)}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </GreyContainer>
  );
};
