import React from 'react';
import {Grid} from '@mui/material';
import {H2} from '../../../components';
import {FilterTile, TileImage} from '.';

export const FilterSection = ({filters, title, renderImage, fullWidth}) => {
  return (
    <>
      <H2 left nomargin>
        {title}
      </H2>
      <Grid container spacing={2} columnSpacing={2}>
        {filters.map((item, index) => (
          <Grid key={item.code} item xs={fullWidth ? 12 : 6} md={6}>
            <Grid container>
              <FilterTile item key={item.code} xs={12} selected={index === 0}>
                {renderImage ? (
                  <TileImage src={item.picture} alt={item.label} />
                ) : (
                  <H2>{item.label}</H2>
                )}
              </FilterTile>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
