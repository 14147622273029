import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Grid} from '@mui/material';
import data from '../data.json';
import {ProductContainer, StepHeadline} from './components';
import {addInitialProduct} from '../../store/basket/basketSlice';

export const LandingPage = () => {
  const dispatch = useDispatch();

  useEffect(
    () =>
      dispatch(
        addInitialProduct({
          label: data.summary.productLabel,
          monthlyInstallmentAmountDifference: Number.parseFloat(
            data.paymentPlan.summary.payMonthly,
          ),
          pictureUrl: data.summary.productPicture,
          reference: data.summary.partNumber,
          total: 907,
        }),
      ),
    [dispatch],
  );

  return (
    <Grid container>
      <StepHeadline />
      <ProductContainer productDetails={data.summary} />
    </Grid>
  );
};
