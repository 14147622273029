import {Box} from '@mui/system';
import React from 'react';

export const FlexContainer = ({children}) => (
  <Box
    sx={{
      display: 'flex',
      minHeight: 'calc(100vh - 301px)',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
  >
    {children}
  </Box>
);
