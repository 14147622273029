import React, {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Box, Container, Grid, Hidden} from '@mui/material';
import {Divider, H2} from '../../components';
import {
  AccessoriesHeadline,
  FiltersHorizontal,
  FiltersVertical,
  ProductList,
} from './components';
import {
  addProduct,
  getProductTotal,
  getProducts,
  removeProduct,
} from '../../store/basket/basketSlice';
import data from '../data.json';
import filterData from './filterData.json';

export const Accessories = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const costPerMonth = useSelector(getProductTotal);
  const shoppingBasket = useSelector(getProducts);

  const [category, setCategory] = useState('default');
  const [products, setProducts] = useState(filterData.products);

  useEffect(() => {
    setProducts(current =>
      current.map(product => ({
        ...product,
        isSelected: !!shoppingBasket.find(
          item => item.reference === product.reference,
        ),
      })),
    );
  }, [setProducts, shoppingBasket]);

  const navigateToProductOverview = () => {
    navigate('/product-overview');
  };

  const removeFromBasket = useCallback(
    reference => {
      dispatch(removeProduct(reference));
    },
    [dispatch],
  );

  const addToBasket = useCallback(
    product => {
      dispatch(addProduct(product));
    },
    [dispatch],
  );

  const updateShoppingBasket = useCallback(
    product => {
      product.isSelected
        ? removeFromBasket(product.reference)
        : addToBasket(product);
    },
    [addToBasket, removeFromBasket],
  );

  return (
    <>
      <AccessoriesHeadline
        costPerMonth={costPerMonth}
        productLabel={data.summary.productLabel}
        onButtonClicked={navigateToProductOverview}
      />
      <Container>
        <Grid container justifyContent="center" py={{xs: 2, md: 4}}>
          <H2 nomargin>{t('accessories.headline')}</H2>
        </Grid>
      </Container>
      <Divider margin="0" />
      <Container disableGutters>
        <Grid container py={0}>
          <Grid item md={3} xs={12} px={1}>
            <Hidden mdDown>
              <FiltersVertical onCategorySelected={setCategory} />
            </Hidden>
            <Hidden mdUp>
              <Box p={1}>
                <FiltersHorizontal
                  category={category}
                  onCategorySelected={setCategory}
                />
              </Box>
              <Divider margin="12" />
            </Hidden>
          </Grid>
          <Grid item md={9} xs={12} py={1}>
            <ProductList
              products={products}
              updateShoppingBasket={updateShoppingBasket}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
