import React from 'react';
import {styled} from '@mui/material';

const SpinnerContainer = styled('div')`
  position: relative;
  width: 100px;
  height: 100px;
  display: inline-block;
  margin-left: 50%;
  margin-right: 50%;
  padding: 50px;
  border-radius: 10px;
  @-webkit-keyframes fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.25;
    }
  }
`;

const BarBase = styled('div')`
  width: 6%;
  height: 16%;
  background: #fff;
  position: absolute;
  left: 49%;
  top: 43%;
  opacity: 0;
  -webkit-border-radius: 50px;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  -webkit-animation: fade 1s linear infinite;
`;

const Bar = styled(BarBase)`
  -webkit-transform: ${props => `rotate(${props.rotationdegrees}deg)`}
    translate(0, -130%);
  -webkit-animation-delay: ${props => props.rotationdelay}s;
`;

export const Spinner = () => {
  const bars = [
    {rotationDegrees: 0, rotationDelay: '0'},
    {rotationDegrees: 30, rotationDelay: '-0.9167'},
    {rotationDegrees: 60, rotationDelay: '-0.833'},
    {rotationDegrees: 90, rotationDelay: '-0.7497'},
    {rotationDegrees: 120, rotationDelay: '-0.667'},
    {rotationDegrees: 150, rotationDelay: '-0.5837'},
    {rotationDegrees: 180, rotationDelay: '-0.5'},
    {rotationDegrees: 210, rotationDelay: '-0.4167'},
    {rotationDegrees: 240, rotationDelay: '-0.333'},
    {rotationDegrees: 270, rotationDelay: '-0.2497'},
    {rotationDegrees: 300, rotationDelay: '-0.167'},
    {rotationDegrees: 330, rotationDelay: '-0.0833'},
  ];

  return (
    <SpinnerContainer>
      {bars.map((item, index) => (
        <Bar
          key={`bar-${index}`}
          rotationdegrees={item.rotationDegrees}
          rotationdelay={item.rotationDelay}
        />
      ))}
    </SpinnerContainer>
  );
};
