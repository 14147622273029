import React from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Button, Grid, styled, Typography} from '@mui/material';
import {DD, DL, DT, Divider, H1} from './';
import {useLocaleNumberFormatting} from '../utils';
import {getBasketTotal, getTerm} from '../store/basket/basketSlice';

const StickyGridContainer = styled(Grid)`
  position: sticky;
  top: 20px;
  text-align: left;
  background-color: ${props => props.theme.palette.secondary.main};
  color: ${props => props.theme.palette.secondary.contrastText};
`;

export const PaymentPlan = ({buttonTranslationKey, onClick}) => {
  const {t} = useTranslation();
  const {formatNumberWithCurrency} = useLocaleNumberFormatting();

  const term = useSelector(getTerm);
  const basketCost = useSelector(getBasketTotal);
  const costPerMonth = Math.round((basketCost / term) * 100) / 100;
  const totalWithoutInitialPayment = costPerMonth * (term - 1);
  const initialPayment = basketCost - totalWithoutInitialPayment;

  const data = {
    summary: {
      term: 12,
      currency: '€',
      payNow: {
        percentage: '25',
        amount: '75.62',
      },
      payMonthly: '75.58',
      total: '907.00',
      heldOnCard: {
        percentage: '25',
        amount: '226.66',
      },
    },
  };

  const getTotalForTermTranslation = () =>
    t('landing.paymentPlan.rows.totalForTerm').replace('{{term}}', term - 1);

  const getInterestValue = () => {
    return `0% / 0,00 ${data.summary.currency}`;
  };

  const Row = ({label, value}) => (
    <DL>
      <DT>{label}</DT>
      <DD bold>{value}</DD>
    </DL>
  );

  return (
    <StickyGridContainer
      mt={5}
      p={4}
      px={{xs: 3, sm: 4, md: 4}}
      pt={3}
      container
    >
      <Grid item xs={12}>
        <Typography variant="body" component="span" fontWeight="bold">
          Piano di pagamento:
        </Typography>
        <Divider />
        <H1 nomargin>
          {`${formatNumberWithCurrency(costPerMonth)} ${t(
            'landing.paymentPlan.title',
          )}`}
        </H1>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Row
          label={t('landing.paymentPlan.rows.payNow')}
          value={formatNumberWithCurrency(initialPayment)}
        />
        <Row
          label={t('landing.paymentPlan.rows.interest')}
          value={getInterestValue()}
        />
        <Row
          label={getTotalForTermTranslation()}
          value={formatNumberWithCurrency(totalWithoutInitialPayment)}
        />
        <Row
          label={t('landing.paymentPlan.rows.total')}
          value={formatNumberWithCurrency(basketCost)}
        />
      </Grid>
      <Divider />
      <Grid item xs={12} pt={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={onClick}
        >
          {t(`landing.paymentPlan.button.${buttonTranslationKey}`)}
        </Button>
      </Grid>
    </StickyGridContainer>
  );
};
