import React from 'react';
import {useTranslation} from 'react-i18next';
import {MenuItem, Select} from '@mui/material';
import {getAllCategories} from './getFilters';

export const FiltersHorizontal = ({category, onCategorySelected}) => {
  const {t} = useTranslation();
  const categories = getAllCategories(t);

  const onSelectChanged = event => onCategorySelected(event.target.value);

  return (
    <Select
      color="primary"
      defaultValue="default"
      value={category}
      onChange={onSelectChanged}
    >
      <MenuItem key="default" value="default" disabled>
        {t('accessories.filters.default.label')}
      </MenuItem>
      {categories.map(item => (
        <MenuItem key={item.code} value={item.code}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};
