import axios from 'axios';

export const postDataToEndpoint = async ({
  endpoint,
  options,
  requestData = {},
  headers,
}) => {
  const {data} = await axios.post(
    `/api/${endpoint}/${options?.search || ''}`,
    requestData,
    {headers: headers},
  );
  return data;
};
