import React from 'react';
import {Container, Grid, styled} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Divider, Link, Logo} from './';

const FooterContainer = styled('div')`
  background-color: ${props => props.theme.palette.footer.main};
  color: ${props => props.theme.palette.footer.contrastText};
  width: 100%;
  min-height: 150px;
`;

const TextLogoContainer = styled('span')`
  vertical-align: top;
  margin-right: 10px;
  line-height: 22px;
`;

export const Footer = () => {
  const {t} = useTranslation();

  return (
    <>
      <Divider margin="0" />
      <Grid container justifyContent="center">
        <Grid item py={2}>
          <Link variant="text" href="/mock/Mock.html">
            {t('footer.backButton')}
          </Link>
        </Grid>
      </Grid>
      <FooterContainer>
        <Container>
          <Grid container pt={6} pb={6}>
            <Grid item xs={12} sx={{alignItems: 'center', textAlign: 'center'}}>
            </Grid>
            <Grid
              item
              pt={2}
              xs={12}
              sx={{alignItems: 'center', textAlign: 'center'}}
            >
              <TextLogoContainer>powered by</TextLogoContainer>
              <Logo src="/heidipay.svg" height="22" alt="heidipay logo" />
            </Grid>
          </Grid>
        </Container>
      </FooterContainer>
    </>
  );
};
