import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {Accessories, Checkout, LandingPage, ProductOverview} from '../pages';
import {Counter} from '../legacy/counter/Counter';
import {CustomerDetails} from '../pages/customer_details';

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/product-configuration" element={<LandingPage />} />
      <Route path="/product-overview" element={<ProductOverview />} />
      <Route path="/product-checkout" element={<Checkout />} />
      <Route path="/add-accessories" element={<Accessories />} />
      <Route path="/legacy" element={<Counter />} />
      <Route path="/customer-details" element={<CustomerDetails />} />
    </Routes>
  );
};
