import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, Divider, Grid} from '@mui/material';
import {H2, PaymentPlan} from '../../components';
import data from '../data.json';

export const ProductOverview = () => {
  const navigate = useNavigate();

  const navigateToCheckoutLink = () => {
    navigate('/customer-details');
  };

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <PaymentPlan
            buttonTranslationKey="continue"
            onClick={navigateToCheckoutLink}
          />
        </Grid>
        <Grid item xs={12} mt={8}>
          <Divider />
          <Grid container py={6}>
            <Grid item md={4}>
              <img
                src={data.summary.productPicture}
                alt={data.summary.productLabel}
              ></img>
            </Grid>
            <Grid item md={8}>
              <H2>{data.summary.productLabel}</H2>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
