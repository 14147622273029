import React from 'react';
import {Box, Container, AppBar, Toolbar} from '@mui/material';
import {Logo} from './';

export const Header = () => {
  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar position="sticky">
        <Container>
          <Toolbar disableGutters>
            <Box sx={{flexGrow: 1}} pt={0.5}>
              <Logo
                align="left"
                src="logo-pagolight-light.png"
                height="40px"
                alt="compass logo"
              />
            </Box>
            <Box sx={{flexGrow: 0}} pt={1} pr={6}>
              <Logo
                align="right"
                src="logo-rstore.png"
                height="40px"
                alt="shop logo"
              />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};
