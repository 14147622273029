import {styled} from '@mui/material';

export const DD = styled('dd')`
  font-weight: ${props =>
    props.bold
      ? props.theme.typography.fontWeightBold
      : props.theme.typography.fontWeightRegular};
`;

export const DL = styled('dl')`
  display: flex;
  justify-content: space-between;
  margin: 0.35rem 0;
`;

export const DT = styled('dt')`
  font-weight: ${props =>
    props.bold
      ? props.theme.typography.fontWeightBold
      : props.theme.typography.fontWeightRegular};
`;

export const Divider = styled('div')`
  width: 100%;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  margin-top: ${props => props.margin || '1rem'};
  margin-bottom: ${props => props.margin || '1rem'};
`;

export const H1 = styled('h1')`
  ${props => props.left && 'float: left;'}
  ${props => props.nomargin && 'margin: 0;'}
`;

export const H2 = styled('h2')`
  ${props => props.left && 'float: left;'}
  ${props => props.nomargin && 'margin: 0;'}
  font-size: 1.6rem;
`;

export const H3 = styled('h3')`
  ${props => props.left && 'float: left;'}
  ${props => props.nomargin && 'margin: 0;'}
  font-size: 1.3rem;
`;

export const Link = styled('a')`
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => props.theme.typography.subtitle1.fontSize};
  line-height: ${props => props.theme.typography.subtitle1.lineHeight};
  font-weight: ${props => props.theme.typography.button.fontWeight};
  text-decoration: none;
`;

export const Logo = styled('img')`
  height: ${props => props.height || 50}px;'
`;
