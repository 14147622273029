import {Grid, styled} from '@mui/material';

export const FilterTile = styled(Grid)`
  cursor: pointer;
  min-height: 90px;
  border: 1px solid
    ${props =>
      props.selected
        ? props.theme.palette.primary.main
        : props.theme.palette.border.main};
  border-radius: 5px;
  text-align: center;
  :hover {
    box-shadow: 0 0 3px 1.5px ${props => props.theme.palette.primary.main};
  }
`;
