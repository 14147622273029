import {createTheme} from '@mui/material';

const theme = createTheme({
  typography: {
    fontSize: 16,
    fontFamily: '-apple-system, system-ui, BlinkMacSystemFont',
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#339de0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#eceeeeb0',
      matt: '#f3f5f5',
      contrastText: '#747474',
    },
    border: {
      main: 'rgba(0,0,0,.1)',
    },
    footer: {
      main: '#eceeeeb0',
      contrastText: '#747474',
    },
    header: {
      main: '#1a1a1a',
      contrastText: '#000',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#1a1a1a',
          color: '#fff',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "San Francisco";
          font-weight: 400;
          src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
        }
      `,
    },
  },
});

export default theme;
