import React from 'react';
import {Backdrop, CircularProgress} from '@mui/material';
import {Spinner} from './Spinner';

export const BackdropOver = ({open, withAppleSpinner}) => {
  return (
    <Backdrop
      sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
      open={open}
    >
      {withAppleSpinner ? (
        <Spinner />
      ) : (
        <CircularProgress disableShrink color="primary" size="40px" />
      )}
    </Backdrop>
  );
};
