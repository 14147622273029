import React from 'react';
import {Container, Grid} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Divider, H1, PaymentPlan} from '../../../components';
import {FilterSection} from './';
import data from '../../data.json';

export const ProductContainer = ({
  productDetails: {partNumber, productLabel, productPicture},
}) => {
  const navigate = useNavigate();

  const navigateToAccessories = () => {
    navigate('/add-accessories');
  };

  return (
    <Container>
      <Grid container pb={4}>
        <Grid item md={6} xs={12}>
          <Grid container item>
            <H1 align="left">{productLabel}</H1>
            <img align="left" src={productPicture} alt={productLabel} />
          </Grid>
          <Filters data={data.possibleCharacteristics} />
        </Grid>
        <Grid item md={6} sm={12} px={{md: 4}} pl={{md: 10}}>
          <PaymentPlan
            buttonTranslationKey="addToBasket"
            onClick={navigateToAccessories}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

const Filters = ({data}) => {
  const {t} = useTranslation();

  const exchangeItemFilter = {
    label: t('landing.filterSection.exchangeItem.title'),
    type: 'exchange',
    values: [
      {
        code: 'exchange-no',
        label: 'No',
      },
      {
        code: 'exchange-si',
        label: 'Si',
      },
    ],
  };

  const filters = [...data, ...[exchangeItemFilter]];

  return (
    <>
      {filters.map(item => (
        <Grid container key={item.type}>
          <Grid item xs={12}>
            <Divider margin="2rem" />
            <FilterSection
              title={item.label}
              filters={item.values}
              renderImage={item.type === 'color'}
              fullWidth={item.type === 'processor'}
            />
          </Grid>
        </Grid>
      ))}
    </>
  );
};
