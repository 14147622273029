import React, {useState} from 'react';
import {styled} from '@mui/material';
import {BackdropOver} from '../../components';
import {useLocation} from 'react-router-dom';

const CheckoutFrame = styled('iframe')`
  border-bottom: none;
  min-height: 800px;
  margin-bottom: 0;
  width: 100%;
`;

export const Checkout = () => {
  const location = useLocation();
  const [isBackdropOpen, setBackdropOpen] = useState(true);

  const closeBackdrop = () => {
    setTimeout(() => setBackdropOpen(false), 1000);
  };
  return (
    <>
      <BackdropOver open={isBackdropOpen} />
      {/* TODO: redirect to error page if url is undefined / null */}
      <CheckoutFrame
        src={location.state.url}
        onLoad={closeBackdrop}
      ></CheckoutFrame>
    </>
  );
};
