import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import en from './en/translations.json';
import it from './it/translations.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    it: {
      translation: it,
    },
  },
});

i18n.changeLanguage('it');

export default i18n;
