import React from 'react';
import {Grid} from '@mui/material';
import {ProductTile} from './ProductTile';

export const ProductList = ({updateShoppingBasket, products}) => {
  return (
    <>
      <Grid container spacing={2} px={{xs: 2, md: 0}}>
        {products.map(product => (
          <Grid key={product.reference} item xs={12} sm={6} md={4}>
            <ProductTile {...product} onButtonClick={updateShoppingBasket} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
