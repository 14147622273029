import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  styled,
} from '@mui/material';
import {getCategoriesWithFilters} from './getFilters';
import {Divider} from '../../../components';

const UL = styled('ul')`
  list-style-type: none;
  margin: 0;
  padding-left: 0;
`;

const LI = styled('li')`
  padding-left: 0;
`;

export const FiltersVertical = ({onCategorySelected}) => {
  const {t} = useTranslation();
  const filters = getCategoriesWithFilters(t);

  const handleCategoryClick = event => {
    onCategorySelected(event.target.dataset.code);
  };

  return (
    <>
      {filters.map((filter, index) => (
        <Accordion key={filter.label} elevation={0}>
          <AccordionSummary
            expandIcon="^"
            aria-controls={`accordion-details-${index}`}
          >
            {filter.label}
          </AccordionSummary>
          <AccordionDetails
            id={`accordion-details-${index}`}
            sx={{padding: 0, paddingLeft: '20px'}}
          >
            <UL>
              {filter.categories.map(category => (
                <LI key={category.code}>
                  <Button
                    data-code={category.code}
                    onClick={handleCategoryClick}
                  >
                    {category.label}
                  </Button>
                </LI>
              ))}
            </UL>
          </AccordionDetails>
        </Accordion>
      ))}
      <Divider />
    </>
  );
};
