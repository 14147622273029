import React from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {Button, Container, Divider, Grid, TextField} from '@mui/material';
import {useNavigate} from 'react-router';
import {useSelector} from 'react-redux';
import {postDataToEndpoint} from '../../store/postDataToEndpoint';
import {getBasketTotal, getProducts} from '../../store/basket/basketSlice';
import {H2} from '../../components';

export const CustomerDetails = () => {
  const selectedProducts = useSelector(getProducts);
  const basketCost = useSelector(getBasketTotal);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const products = selectedProducts.map(product => {
    return {
      name: product.label || product.reference,
      quantity: 1,
      price: product.total,
    };
  });

  const initCheckoutPayload = {
    amount: {
      amount: basketCost,
      currency: 'EUR',
    },
    products: products,
  };

  const submitCustomerDetails = async customerDetails => {
    const data = await postDataToEndpoint({
      endpoint: 'org/init-checkout',
      requestData: {...initCheckoutPayload, ...customerDetails},
    });

    navigate('/product-checkout', {
      state: {
        url: data.redirect_url,
      },
    });
  };

  const validationSchema = yup.object({
    first_name: yup
      .string('Enter your first name')
      .required(t('customerDetailsPage.firstName.required')),
    last_name: yup
      .string('Enter your last name')
      .required(t('customerDetailsPage.lastName.required')),
    email_address: yup
      .string('Enter your email address')
      .email(t('customerDetailsPage.email.invalid'))
      .required(t('customerDetailsPage.email.required')),
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email_address: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      submitCustomerDetails(values);
    },
  });

  return (
    <>
      <Container disableGutters={true}>
        <Grid container justifyContent="center" py={{xs: 2, md: 4}}>
          <H2 nomargin>{t('customerDetailsPage.customerDetails')}</H2>
        </Grid>
        <Divider margin="0" />
      </Container>
      <Container maxWidth="sm" sx={{justifyContent: 'center'}}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            id="first_name"
            name="first_name"
            label={t('customerDetailsPage.firstName.label')}
            value={formik.values.first_name}
            onChange={formik.handleChange}
            error={
              formik.touched.first_name && Boolean(formik.errors.first_name)
            }
            helperText={formik.touched.first_name && formik.errors.first_name}
          />
          <TextField
            fullWidth
            margin="normal"
            id="last_name"
            name="last_name"
            label={t('customerDetailsPage.lastName.label')}
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
          />
          <TextField
            fullWidth
            margin="normal"
            id="email_address"
            name="email_address"
            label={t('customerDetailsPage.email.label')}
            value={formik.values.email_address}
            onChange={formik.handleChange}
            error={
              formik.touched.email_address &&
              Boolean(formik.errors.email_address)
            }
            helperText={
              formik.touched.email_address && formik.errors.email_address
            }
          />
          <Button
            sx={{marginTop: '20px'}}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            size="large"
          >
            {t('customerDetailsPage.submit')}
          </Button>
        </form>
      </Container>
    </>
  );
};
