import {useTranslation} from 'react-i18next';

export const useLocaleNumberFormatting = () => {
  const {i18n, t} = useTranslation();
  const code = t('currency.code');
  const numberFormatter = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: code,
  });
  const formatNumberWithCurrency = number =>
    numberFormatter.format(Number.parseFloat(number));

  return {formatNumberWithCurrency};
};
