import React, {Suspense} from 'react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {AppRouter} from './router';
import theme from './theme';
import {BackdropOver, Footer, Header} from './components';
import {ScrollToTop} from './router/ScrollToTop';
import {FlexContainer} from './components/FlexContainer';

function App() {
  return (
    <Suspense fallback={<BackdropOver />}>
      <div className="App">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Header />
          <FlexContainer>
            <AppRouter />
          </FlexContainer>
          <ScrollToTop />
          <Footer />
        </ThemeProvider>
      </div>
    </Suspense>
  );
}

export default App;
