import React from 'react';
import {Container, Grid, styled} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {H2} from '../../../components';

const Header = styled('div')`
  background-color: #339de0;
  color: #fff;
  font-weight: bold;
  padding: 1em 0.5em 2em 0.5em;
`;

const Bubble = styled('div')`
  margin: auto;
  text-align: center;
  border: 3px solid #fff;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  padding-top: 4px;
`;

export const StepHeadline = () => {
  const {t} = useTranslation();
  const steps = [
    t('landing.stepHeadline.selectFinanceType'),
    t('landing.stepHeadline.includeUsedDevice'),
    t('landing.stepHeadline.financeAgreement'),
  ];

  const Step = ({index, text}) => {
    return (
      <Grid item key={text} md={3} sm={12}>
        <Grid container>
          <Grid item xs={2} md={12}>
            <Bubble>{index}</Bubble>
          </Grid>
          <Grid item align="center" xs={9} md={12}>
            {text}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Header>
          <H2 align="center">{t('landing.stepHeadline.title')}</H2>
          <Container>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              {steps.map((item, index) => (
                <Step key={item} index={index + 1} text={item} />
              ))}
            </Grid>
          </Container>
        </Header>
      </Grid>
    </Grid>
  );
};
